import React from 'react'
import Layout from 'layouts/fr'
import styled from 'styled-components'
import design from 'design'
import { Title, Text, Row, Div, Box, Button } from 'components'
import { TryOrSchedule } from 'containers/fr'
import YouTube from 'react-youtube-embed'
import { getYoutubeId } from 'utils'

const Video = ({ className, youtube }) => {
  const id = getYoutubeId(youtube)
  return (
    <Div className={'video'} mx={[-36,0]}>
      <YouTube id={id} />
    </Div>
  )
}

export default () => {
  return (
    <Layout p={5}>
      <Div pt={[6,8]} px={[0,6]} textAlign={['center', 'left']}>
        <Title mb={5} fontSize={[7,9]}>Maitriser les bases de Stairlin en 15 minutes</Title>
        <Text mb={8} fontSize={[6,8]}>Ces vidéos courtes feront de vous un pro en un clin d'œil</Text>

        <Div tag={'article'} mb={[6,8]} display={'flex'} flexDirection={['column-reverse','column']}>
          <Div textAlign={'left'} py={[4,0]} px={[5,0]} mx={[-36,0]} bg={['white','transparent']} boxShadow={['black','none']}>
            <Title mb={[3,4]} fontSize={[3,7]}>Créer son entreprise</Title>
            <Text mb={[0,6]} fontSize={[2,5]}>Ce tutoriel va vous permettre de créer votre entreprise sur Stairlin.</Text>
          </Div>
          <Video youtube={'https://www.youtube.com/watch?v=XjFv51JdmpM'} />
        </Div>

        <Div tag={'article'} mb={[6,8]} display={'flex'} flexDirection={['column-reverse','column']}>
          <Div textAlign={'left'} py={[4,0]} px={[5,0]} mx={[-36,0]} bg={['white','transparent']} boxShadow={['black','none']}>
            <Title mb={[3,4]} fontSize={[3,7]}>Paramétrer son entreprise</Title>
            <Text mb={[0,6]} fontSize={[2,5]}>Ce tutoriel va vous permettre de paramétrer votre entreprise en moins de 10 minutes.</Text>
          </Div>
          <Video youtube={'https://www.youtube.com/watch?v=nQuuMzErd1o'} />
        </Div>

        <Div tag={'article'} mb={[6,8]} display={'flex'} flexDirection={['column-reverse','column']}>
          <Div textAlign={'left'} py={[4,0]} px={[5,0]} mx={[-36,0]} bg={['white','transparent']} boxShadow={['black','none']}>
            <Title mb={[3,4]} fontSize={[3,7]}>Créer une réservation</Title>
            <Text mb={[0,6]} fontSize={[2,5]}>Ce tutoriel va vous permettre de savoir comment prendre des réservations pour vous clients.</Text>
          </Div>
          <Video youtube={'https://www.youtube.com/watch?v=w7ySKBjyi2E'} />
        </Div>

        <Div tag={'article'} mb={[6,8]} display={'flex'} flexDirection={['column-reverse','column']}>
          <Div textAlign={'left'} py={[4,0]} px={[5,0]} mx={[-36,0]} bg={['white','transparent']} boxShadow={['black','none']}>
            <Title mb={[3,4]} fontSize={[3,7]}>Paramètrer Stairlin pour T590</Title>
            <Text mb={[0,6]} fontSize={[2,5]}>Paramétrez votre compte Stairlin pour pouvoir générer des factures T590 en quelques secondes grâce à cette vidéo.</Text>
          </Div>
          <Video youtube={'https://www.youtube.com/watch?v=w7ySKBjyi2E'} />
        </Div>

        <Div tag={'article'} mb={[6,8]} display={'flex'} flexDirection={['column-reverse','column']}>
          <Div textAlign={'left'} py={[4,0]} px={[5,0]} mx={[-36,0]} bg={['white','transparent']} boxShadow={['black','none']}>
            <Title mb={[3,4]} fontSize={[3,7]}>Factures T590 générées automatiquement</Title>
            <Text mb={[0,6]} fontSize={[2,5]}>Laissez Stairlin générer les factures T590 automatiquement pour vous! Que vos patients réservent en ligne ou que vous preniez les rendez-vous pour eux, Stairlin se charge de générer les factures et de les leur faire parvenir.</Text>
          </Div>
          <Video youtube={'https://www.youtube.com/watch?v=w7ySKBjyi2E'} />
        </Div>

        <Div tag={'article'} mb={[6,8]} display={'flex'} flexDirection={['column-reverse','column']}>
          <Div textAlign={'left'} py={[4,0]} px={[5,0]} mx={[-36,0]} bg={['white','transparent']} boxShadow={['black','none']}>
            <Title mb={[3,4]} fontSize={[3,7]}>Créer des factures T590 sur Stairlin</Title>
            <Text mb={[0,6]} fontSize={[2,5]}>Créer vos factures T590 sur Stairlin en quelques secondes avec Stairlin.</Text>
          </Div>
          <Video youtube={'https://www.youtube.com/watch?v=w7ySKBjyi2E'} />
        </Div>

        <Div mt={8}>
          <TryOrSchedule />
        </Div>
      </Div>
    </Layout>
  )
}
